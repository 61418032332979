const initialState = {
  agentData: [],
};

const manageAgentReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_AGENTS":
      return {
        ...state,
        agentData: action.payload,
      };
    case "ADD_TO_AGENTS":
      return {
        ...state,
        agentData: [...state.agentData, action.payload],
      };
    case "UPDATE_AGENT":
      return {
        ...state,
        agentData: state.agentData.map((agent) =>
          agent.id === action.payload.id ? action.payload.agent : agent
        ),
      };
    case "DELETE_AGENT":
    default:
      return state;
  }
};

export default manageAgentReducer;
