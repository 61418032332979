import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setSelectedConversationData } from "../redux/actions/conversationActions";
import useNotification from "../hooks/useNotification";

const useListenNewMessage = ({ socket }) => {
  const { selectedConversationId, selectedConversationData } = useSelector(
    (state) => state.conversation
  );
  const dispatch = useDispatch();
  const { handleNotification } = useNotification();
  useEffect(() => {
    if (socket) {
      const handleNewMessage = (newMessage) => {
        if (selectedConversationId === newMessage.conversationId) {
          const currentChatData = { ...selectedConversationData };
          currentChatData.messages = currentChatData.messages || [];
          currentChatData.messages.push(newMessage);
          dispatch(setSelectedConversationData(currentChatData));
          handleNotification(
            "Alert",
            "New Message from " + newMessage.customer.email
          );
        }
      };
      socket.on("newMessage", handleNewMessage);
      return () => socket.off("newMessage", handleNewMessage);
    }
  }, [socket, selectedConversationId, selectedConversationData, dispatch]);
  return null;
};

export default useListenNewMessage;
