import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  markAsClosed,
  removeConversationFromSideBar,
  setSelectedConversationData,
  setSelectedConversationId,
} from "../redux/actions/conversationActions";
import Modal from "../components/Modal/Modal";
import { IoWarningOutline } from "react-icons/io5";

const useListenAutoTransferChat = ({ socket }) => {
  const { selectedConversationId, selectedConversationData } = useSelector(
    (state) => state.conversation
  );
  const [openModal, setOpenModal] = useState(false);
  const [modalMessage, setModalMessage] = useState(
    "Conversation transfered to another Agent due to inactivity"
  );
  const dispatch = useDispatch();

  useEffect(() => {
    if (socket) {
      const eventFunction = (payload) => {
        console.log(payload);
        dispatch(setSelectedConversationData({}));
        dispatch(setSelectedConversationId(""));
        setOpenModal(true);
        setModalMessage(payload.modalMessage);
        dispatch(removeConversationFromSideBar(payload.conversationId));
      };
      socket.on("autoTransferChat", eventFunction);
      return () => socket.off("autoTransferChat", eventFunction);
    }
  }, [socket, selectedConversationId, selectedConversationData, dispatch]);
  return {
    modalBox: (
      <Modal
        heading={"Chat Transfered"}
        description={modalMessage}
        buttonText={"Done"}
        svg={<IoWarningOutline id="cookieSvg" />}
        openModal={openModal}
        closeFunction={() => {
          setOpenModal(false);
        }}
      />
    ),
  };
};

export default useListenAutoTransferChat;
