import "./Login.css";
import CustomText from "../../components/FormComponents/Text/CustomText";
import { MdLogin } from "react-icons/md";
import useHandleFormData, {
  performValidation,
} from "../../hooks/useHandleFormData";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";

const Login = () => {
  const { formData, errors, handleChange, handleErrors, toast, setFormData } =
    useHandleFormData();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const handleSubmit = () => {
    const validationConfig = [
      {
        name: "email",
        validations: ["required"],
        label: "email",
      },
      {
        name: "password",
        validations: ["required"],
        label: "Password",
      },
    ];
    const validationResults = performValidation(validationConfig, formData);
    handleErrors(validationResults);
    if (Object.keys(validationResults).length === 0) {
      let bodyData = {
        email: formData.email,
        password: formData.password,
      };
      try {
        setLoading(true);
        axios
          .post("/api/login", bodyData)
          .then((res) => {
            toast(res.data.message || "Login Successful", "successToast");
            setFormData({
              email: "",
              password: "",
            });
            localStorage.setItem("token", res.data.token);
            localStorage.setItem("role", res.data.role);
            if (res.data.role === "agent") {
              navigate("/agent");
            } else if (res.data.role === "admin") {
              navigate("/admin");
            }
            setLoading(false);
          })
          .catch((err) => {
            toast(err?.response?.data?.error || "Login failed", "errorToast");
            setLoading(false);
          });
      } catch (err) {
        toast("Oops! Something went wrong", "errorToast");
        setLoading(false);
      }
    }
  };
  return (
    <div className="login-page-container">
      <div className="login-element-container">
        <div className="login-page-heading">Log in to your account</div>
        <div className="login-form-container">
          <CustomText
            flex={"100%"}
            label={"Username"}
            placeholder={"Username"}
            onChange={(e) => {
              handleChange(e, "email");
            }}
            error={errors?.email}
            value={formData.email}
            fieldStyle={{
              background: "transparent",
              border: "1px solid rgb(85 85 85)",
              color: "white",
            }}
            labelStyle={{ color: "rgb(189, 184, 184)", fontSize: "var(--font-16-px)" }}
          ></CustomText>
          <CustomText
            flex={"100%"}
            label={"Password"}
            placeholder={"Password"}
            type={"password"}
            onChange={(e) => {
              handleChange(e, "password");
            }}
            error={errors?.password}
            value={formData.password}
            labelStyle={{
              color: "rgb(189, 184, 184)",
              fontSize: "var(--font-16-px)",
            }}
            fieldStyle={{
              background: "transparent",
              border: "1px solid rgb(85 85 85)",
              color: "white",
            }}
          ></CustomText>
          <button
            onClick={handleSubmit}
            type="button"
            className="login-button"
            disabled={loading}
          >
            {loading ? (
              <div className="button-spinner-container-new">
                <div className="button-spinner-new"></div>
              </div>
            ) : (
              <>
                <span>Sign in</span> <MdLogin />
              </>
            )}
          </button>
          <Link className="forgot-password-link" to="/forgotpassword">
            Forgot Password ?
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Login;
