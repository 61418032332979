import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  markAsClosed,
  setSelectedConversationData,
} from "../redux/actions/conversationActions";

const useListenAutoEndConversationNoReplyCustomer = ({ socket }) => {
  const { selectedConversationId, selectedConversationData } = useSelector(
    (state) => state.conversation
  );
  const dispatch = useDispatch();

  useEffect(() => {
    if (socket) {
      const eventFunction = (payload) => {
        if (selectedConversationId === payload.conversationId) {
          const currentChatData = { ...selectedConversationData };
          currentChatData.messages = currentChatData.messages || [];
          currentChatData.messages.push(payload);
          //   push new message to the conversation
          dispatch(setSelectedConversationData(currentChatData));
        }
        // mark as closed
        dispatch(markAsClosed(payload.conversationId));
      };
      socket.on("autoEndConversationNoReplyCustomer", eventFunction);
      return () =>
        socket.off("autoEndConversationNoReplyCustomer", eventFunction);
    }
  }, [socket, selectedConversationId, selectedConversationData, dispatch]);
  return null;
};

export default useListenAutoEndConversationNoReplyCustomer;
