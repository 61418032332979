const initialState = {
  sidebarConversations: [],
  selectedConversationId: "",
  selectedUserId: null,
  selectedUserData: {},
  selectedConversationData: {},
  allMessages: [],
};

const conversationReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_SIDEBAR_CONVERSATIONS":
      return {
        ...state,
        sidebarConversations: action.payload,
      };
    case "ADD_TO_ALL_MESSAGES":
      return {
        ...state,
        allMessages: [...state.allMessages, action.payload],
      };
    case "CLEAR_STORE":
      return initialState;
    case "ADD_TO_ALL_MESSAGES_BULK":
      return {
        ...state,
        allMessages: action.payload,
      };
    case "SET_SELECTED_CONVERSATION_ID":
      return {
        ...state,
        selectedConversationId: action.payload,
      };
    case "SET_SELECTED_CONVERSATION_DATA":
      return {
        ...state,
        selectedConversationData: action.payload,
      };
    case "SET_CURRENT_USER_ID":
      return {
        ...state,
        selectedUserId: action.payload,
      };
    case "SET_CURRENT_USER_DATA":
      return {
        ...state,
        selectedUserData: action.payload,
      };
    case "ADD_NEW_CONVERSATION_TO_SIDEBAR":
      return {
        ...state,
        sidebarConversations: [action.payload, ...state.sidebarConversations],
      };
    case "RESET_ALL_MESSAGES":
      return {
        ...state,
        allMessages: [],
      };
    case "MARK_AS_CLOSED":
      return {
        ...state,
        sidebarConversations: state.sidebarConversations.map((convo) => {
          return convo._id === action.payload
            ? { ...convo, status: true }
            : convo;
        }),
        selectedConversationData:
          state.selectedConversationData._id === action.payload
            ? { ...state.selectedConversationData, status: true }
            : state.selectedConversationData,
      };
    case "REMOVE_CONVERSATION_FROM_SIDEBAR":
      return {
        ...state,
        sidebarConversations: state.sidebarConversations.filter(
          (convo) => convo._id !== action.payload
        ),
        selectedConversationData:
          state.selectedConversationData._id === action.payload
            ? {}
            : state.selectedConversationData,
        selectedConversationId:
          state.selectedConversationData._id === action.payload
            ? ""
            : state.selectedConversationId,
      };
    default:
      return state;
  }
};

export default conversationReducer;
