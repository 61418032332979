import React from "react";
import { Navigate, useNavigate, useRoutes } from "react-router-dom";
import jwt_decode from "jwt-decode";
import { useDispatch } from "react-redux";
import { showToastMessage } from "./redux/actions/toastActions";
import Login from "./containers/Login/Login";
import ChatAgent from "./containers/ChatAgent/ChatAgent";
import ChatAdmin from "./containers/ChatAdmin/ChatAdmin";
import NotFound from "./containers/NotFound";
import ForgotPassword from "./containers/ForgotPassword/ForgotPassword";
import AdminLayout from "./layouts/AdminLayout/AdminLayout";
import AgentManagement from "./containers/AgentManagement/AgentManagement";
import AgentLayout from "./layouts/AgentLayout/AgentLayout";
// import ChatWindow from "./containers/Customer/ChatWindow";
export default function Router() {
  function isTokenExpired(token) {
    if (!token) {
      return true;
    }
    try {
      const decoded = jwt_decode(token);
      const currentTime = Date.now() / 1000;
      return decoded.exp < currentTime;
    } catch (error) {
      console.error("Invalid token:", error);
      return true;
    }
  }

  const dispatch = useDispatch();
  const navigate = useNavigate();

  let isAuthenticated = false;
  if (localStorage.getItem("token")) {
    if (isTokenExpired(localStorage.getItem("token")) === true) {
      // if (!localStorage.getItem("token")) {
      isAuthenticated = false;
      localStorage.removeItem("token");
      localStorage.removeItem("role");
      dispatch(showToastMessage("Session Timeout", "warningToast"));
      navigate("/");
    } else {
      isAuthenticated = true;
    }
  } else {
    isAuthenticated = false;
  }

  const getUserRole = () => {
    if (localStorage.getItem("token")) {
      const decoded = jwt_decode(localStorage.getItem("token"));
      if (decoded.role === "admin") {
        return "admin";
      } else {
        return "agent";
      }
    }
  };

  const routes = useRoutes([
    {
      path: "/agent",
      // element: isAuthenticated ? <AgentLayout /> : <Navigate to="/" replace />,
      element: isAuthenticated ? (
        getUserRole() === "agent" ? (
          <AgentLayout />
        ) : (
          <Navigate to="/admin" replace />
        )
      ) : (
        <Navigate to="/" replace />
      ),
      children: [{ path: "", element: <ChatAgent /> }],
    },

    {
      path: "/admin",
      // element: isAuthenticated ? <AdminLayout /> : <Navigate to="/" replace />,
      element: isAuthenticated ? (
        getUserRole() === "admin" ? (
          <AdminLayout />
        ) : (
          <Navigate to="/agent" replace />
        )
      ) : (
        <Navigate to="/" replace />
      ),
      children: [
        { path: "", element: <ChatAdmin /> },
        { path: "management/agent", element: <AgentManagement /> },
      ],
    },

    {
      path: "/",
      element: isAuthenticated ? (
        <Navigate to={`/${getUserRole()}`} replace />
      ) : (
        <Login />
      ),
    },

    // {
    //   path: "/customer",
    //   element: isAuthenticated ? (
    //     <Navigate to={`/${getUserRole()}`} replace />
    //   ) : (
    //     <ChatWindow />
    //   ),
    // },

    {
      path: "/forgotpassword",
      element: isAuthenticated ? (
        <Navigate to={`/${getUserRole()}`} replace />
      ) : (
        <ForgotPassword />
      ),
    },

    { path: "*", element: <NotFound /> },
  ]);

  return routes;
}
