import { useEffect, useState } from "react";
import "./AgentManagement.css";
import { headerData, caption } from "./TableConfig";
import CardTable from "../../components/CardTable/CardTable";
import axios from "axios";
import {
  ConvertDateTime,
  ISO_to_HTML_Format,
} from "../../functions/DateFunctions";
import AddAgent from "./AddAgent/AddAgent";
import UpdateAgent from "./UpdateAgent/UpdateAgent";
import { useDispatch, useSelector } from "react-redux";
import { setAgents } from "../../redux/actions/manageAgentActions";
import { IoArrowBack } from "react-icons/io5";
import { Link } from "react-router-dom";
const AgentManagement = () => {
  const [openAddNewAgent, setOpenAddNewAgent] = useState(false);
  const [openUpdateAgent, setOpenUpdateAgent] = useState(false);
  const [selectedAgent, setSelectedAgent] = useState({});
  // const [agentData, setAgentData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [refreshList, setRefreshList] = useState(false);

  const { agentData } = useSelector((state) => state.agent);
  const dispatch = useDispatch();
  useEffect(() => {
    try {
      setLoading(true);
      axios
        .get(`/api/fetchAllAgents`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        })
        .then((res) => {
          setLoading(false);
          dispatch(setAgents(res.data.data));
          // setAgentData(res.data.data);
        })
        .catch((e) => {
          console.log(e);
          setLoading(false);
        });
    } catch (error) {
      setLoading(false);
      console.log("Error fetching agent data", error);
    }
  }, [refreshList]);

  const tableData = Array.isArray(agentData)
    ? agentData.map((data, index) => {
        return {
          tableViewInfo: [
            "",
            data.fullName,
            data.email,
            data.accountStatus === "active" ? (
              <span style={{ color: "#3d893d" }}>Active</span>
            ) : (
              <span style={{ color: "#b15959" }}>Inactive</span>
            ),

            ISO_to_HTML_Format(data.createdAt),
          ],
          onClickTr: () => {
            setSelectedAgent(data);
            setOpenUpdateAgent(true);
          },
          id: index,
        };
      })
    : [];
  return (
    <div className="admin-all-chapters-container">
      <div className="admin-all-agents-header">
        <div className="admin-agent-management-heading">
          <Link to="/admin" className="back-button-manage-agent">
            <IoArrowBack />
          </Link>
          Agent Management
        </div>
        <button
          className="add-new-agent-button"
          onClick={() => {
            setOpenAddNewAgent(true);
          }}
        >
          New Agent
        </button>
      </div>
      {loading ? (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
            height: "100%",
          }}
        >
          <div className="button-spinner-container-new">
            <div className="button-spinner-new"></div>
          </div>
        </div>
      ) : (
        <div style={{ overflow: "auto" }}>
          <CardTable
            caption={caption}
            headerData={headerData}
            tableData={tableData}
            disableRowClick={false}
          />
        </div>
      )}

      {openAddNewAgent && (
        <AddAgent
          closeFunction={() => {
            setOpenAddNewAgent(false);
          }}
          setRefreshList={setRefreshList}
        />
      )}

      {openUpdateAgent && selectedAgent && (
        <UpdateAgent
          selectedAgent={selectedAgent}
          closeFunction={() => {
            setOpenUpdateAgent(false);
            setSelectedAgent({});
          }}
        />
      )}
    </div>
  );
};

export default AgentManagement;
