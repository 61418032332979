import { useEffect, useState } from "react";
import "../FormComponentsTheme.css";
import { FaEye } from "react-icons/fa";
import { FaEyeSlash } from "react-icons/fa";
const CustomText = ({
  label,
  error,
  placeholder,
  value,
  onChange,
  labelClassName,
  fieldClassName,
  disabled,
  fieldStyle,
  labelStyle,
  flex,
  type,
  name,
  tooltip,
  autocomplete,
}) => {
  const [customType, setCustomType] = useState();
  const [showPassword, setShowPassword] = useState(false);
  useEffect(() => {
    if (type === undefined) {
      setCustomType("text");
    } else if (type === "date") {
      setCustomType("date");
    } else if (type === "password") {
      setCustomType("password");
    } else if (type === "text") {
      setCustomType("text");
    } else if (type === "time") {
      setCustomType("time");
    } else {
      setCustomType(type);
    }
  });

  return (
    <div className="custom-input-container" style={{ flex: flex }}>
      <span>
        <label
          style={labelStyle}
          className={"custom-text-label " + labelClassName || ""}
        >
          {label}
        </label>{" "}
        {tooltip ? tooltip : ""}
      </span>
      <div style={{ position: "relative" }}>
        <input
          name={name || "Field"}
          style={fieldStyle}
          autocomplete={autocomplete ? autocomplete : ""}
          type={showPassword ? "text" : customType}
          placeholder={placeholder || ""}
          value={value}
          onChange={(e) => {
            onChange(e);
          }}
          className={"custom-text-field " + fieldClassName || ""}
          disabled={disabled === undefined ? false : disabled ? true : false}
        ></input>
        {type === "password" &&
          (showPassword ? (
            <FaEyeSlash
              className="password-open-eye"
              onClick={() => {
                setShowPassword(false);
              }}
            />
          ) : (
            <FaEye
              className="password-open-eye"
              onClick={() => {
                setShowPassword(true);
              }}
            />
          ))}
      </div>

      {["LinkedIn", "Facebook", "Instagram"].includes(label) ? (
        value && <span className="form-error-message">{error}</span>
      ) : (
        <span className="form-error-message">{error}</span>
      )}
    </div>
  );
};

export default CustomText;
