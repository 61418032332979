import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  markAsClosed,
  setSelectedConversationData,
} from "../redux/actions/conversationActions";

const useListenAutoEndConversationZeroMessages = ({ socket }) => {
  const { selectedConversationId, selectedConversationData } = useSelector(
    (state) => state.conversation
  );
  const dispatch = useDispatch();

  useEffect(() => {
    if (socket) {
        console.log(
        "useListenAutoEndConversationZeroMessages.js: socket is defined"
      );
      const eventFunction = (payload) => {
        console.log(payload);
        if (selectedConversationId === payload.conversationId) {
          const currentChatData = { ...selectedConversationData };
          currentChatData.messages = currentChatData.messages || [];
          currentChatData.messages.push(payload);
          //   push new message to the conversation
          dispatch(setSelectedConversationData(currentChatData));
        }
        // mark as closed
        dispatch(markAsClosed(payload.conversationId));
      };
      socket.on("autoEndConversationZeroMessages", eventFunction);
      return () => socket.off("autoEndConversationZeroMessages", eventFunction);
    }
  }, [socket, selectedConversationId, selectedConversationData, dispatch]);
  return null;
};

export default useListenAutoEndConversationZeroMessages;
