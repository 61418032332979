import React, { useState } from "react";
import ChatWindowAgent from "../ChatAgent/ChatWindow/ChatWindowAgent";
import useListenCompletedChat from "../../SocketListeners/useListenCompletedChat";
import useSocketConnection from "../../hooks/useSocketConnection";
import useSetCurrentUserId from "../ChatAgent/ChatWindow/hooks/useSetCurrentUserId";

const ChatAdmin = () => {
  useSetCurrentUserId();
  const { socket } = useSocketConnection();
  useListenCompletedChat({ socket: socket });

  return <ChatWindowAgent view={"admin"} />;
};

export default ChatAdmin;
