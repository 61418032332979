import "../FormComponentsTheme.css";
const CustomSelect = ({
  label,
  error,
  value,
  onChange,
  labelClassName,
  fieldClassName,
  disabled,
  fieldStyle,
  labelStyle,
  flex,
  type,
  name,
  tooltip,
  autocomplete,
  options,
}) => {
  let fieldType = "text";
  if (type === undefined) {
    fieldType = "text";
  } else if (type === "date") {
    fieldType = "date";
  } else if (type === "password") {
    fieldType = "password";
  } else if (type === "text") {
    fieldType = "text";
  } else if (type === "time") {
    fieldType = "time";
  } else {
    fieldType = "text";
  }
  return (
    <div className="custom-input-container" style={{ flex: flex }}>
      <span>
        <label
          style={labelStyle}
          className={"custom-text-label " + labelClassName || ""}
        >
          {label}
        </label>{" "}
        {tooltip ? tooltip : ""}
      </span>

      <div className="select-wrapper">
        <select
          className={"select-field " + fieldClassName || ""}
          disabled={disabled === undefined ? false : disabled ? true : false}
          value={value}
          name={name || "Field"}
          autocomplete={autocomplete ? autocomplete : ""}
          style={fieldStyle}
          onChange={(e) => {
            onChange(e);
          }}
        >
          {Array.isArray(options)
            ? options.map((option, index) => (
                <option
                  key={index}
                  value={option?.value}
                  style={{
                    color: "#d3d2d2",
                    padding: "10px 5px",
                    background: "#4f4d4d",
                  }}
                >
                  {option?.label}
                </option>
              ))
            : null}
        </select>
      </div>

      {["LinkedIn", "Facebook", "Instagram"].includes(label) ? (
        value && <span className="form-error-message">{error}</span>
      ) : (
        <span className="form-error-message">{error}</span>
      )}
    </div>
  );
};

export default CustomSelect;
