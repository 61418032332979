import React, { useState } from "react";
import CustomText from "../../components/FormComponents/Text/CustomText";
import useHandleFormData, {
  performValidation,
} from "../../hooks/useHandleFormData";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import "./ForgotPassword.css";

const ForgotPassword = () => {
  const { formData, errors, handleChange, handleErrors, toast, setFormData } =
    useHandleFormData();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [loadingSendOtp, setLoadingSendOtp] = useState(false);
  const [disableResetButton, setDisableResetButton] = useState(true);
  const [disableSendOtpButton, setDisableSendOtpButton] = useState(false);

  const handleSubmit = () => {
    const validationConfig = [
      {
        name: "email",
        validations: ["required"],
        label: "email",
      },
      {
        name: "otp",
        validations: ["required"],
        label: "otp",
      },
      {
        name: "password",
        validations: ["required"],
        label: "Password",
      },
    ];
    const validationResults = performValidation(validationConfig, formData);
    handleErrors(validationResults);
    if (Object.keys(validationResults).length === 0) {
      let bodyData = {
        email: formData.email,
        password: formData.password,
        otp: formData.otp,
      };
      try {
        setDisableResetButton(true);
        setLoading(true);
        axios
          .post("/api/resetPassword", bodyData)
          .then((res) => {
            setLoading(false);
            toast(res?.data?.message || "Success", "successToast");
            navigate("/");
          })
          .catch((err) => {
            toast(
              err?.response?.data?.error || "Failed to reset",
              "errorToast"
            );
            setDisableResetButton(false);
            setLoading(false);
          });
      } catch (err) {
        setDisableResetButton(false);
        toast("Oops! Something went wrong", "errorToast");
        setLoading(false);
      }
    }
  };

  const handleSendOtp = () => {
    const validationConfig = [
      {
        name: "email",
        validations: ["required"],
        label: "email",
      },
    ];
    const validationResults = performValidation(validationConfig, formData);
    handleErrors(validationResults);
    if (Object.keys(validationResults).length === 0) {
      let bodyData = {
        email: formData.email,
      };
      try {
        setDisableSendOtpButton(true);
        setLoadingSendOtp(true);
        axios
          .post("/api/sendPasswordResetLink", bodyData)
          .then((res) => {
            toast(res.data.message || "Email has been sent", "successToast");
            setDisableResetButton(false);
            setLoadingSendOtp(false);
          })
          .catch((err) => {
            setDisableSendOtpButton(false);
            setFormData({
              email: "",
            });
            toast(
              err?.response?.data?.error || "Failed to send Email",
              "errorToast"
            );
            setLoadingSendOtp(false);
          });
      } catch (err) {
        setDisableSendOtpButton(false);
        toast("Oops! Something went wrong", "errorToast");
        setLoadingSendOtp(false);
      }
    }
  };

  return (
    <div className="login-page-container">
      <div className="login-element-container">
        <div className="login-page-heading">Reset Password</div>
        <div className="login-form-container">
          <div className="email-send-container-forgot">
            <CustomText
              flex={"3"}
              label={"Email"}
              autocomplete="off"
              placeholder={"Email"}
              onChange={(e) => {
                handleChange(e, "email");
              }}
              disabled={disableSendOtpButton}
              error={errors?.email}
              value={formData.email}
              fieldStyle={{
                background: "transparent",
                border: "1px solid rgb(85 85 85)",
                color: "white",
              }}
              labelStyle={{
                color: "rgb(189, 184, 184)",
                fontSize: "var(--font-16-px)",
              }}
            ></CustomText>
            <button
              onClick={handleSendOtp}
              style={{
                cursor: disableSendOtpButton ? "not-allowed" : "pointer",
              }}
              type="button"
              className="forgot-pw-send-email-button"
              disabled={disableSendOtpButton}
            >
              {loadingSendOtp === true ? (
                <div className="button-spinner-container-new">
                  <div className="button-spinner-new"></div>
                </div>
              ) : (
                <>
                  <span>Send OTP</span>
                </>
              )}
            </button>
          </div>
          {/* OTP Field */}
          <CustomText
            flex={"100%"}
            autocomplete="off"
            label={"OTP"}
            placeholder={"OTP"}
            onChange={(e) => {
              handleChange(e, "otp");
            }}
            disabled={disableResetButton}
            error={errors?.otp}
            value={formData.otp}
            fieldStyle={{
              background: "transparent",
              border: "1px solid rgb(85 85 85)",
              color: "white",
            }}
            labelStyle={{
              color: "rgb(189, 184, 184)",
              fontSize: "var(--font-16-px)",
            }}
          ></CustomText>
          {/* New Password Field */}
          <CustomText
            flex={"100%"}
            label={"New Password"}
            disabled={disableResetButton}
            placeholder={"New Password"}
            type={"password"}
            onChange={(e) => {
              handleChange(e, "password");
            }}
            autocomplete="off"
            error={errors?.password}
            value={formData.password}
            labelStyle={{
              color: "rgb(189, 184, 184)",
              fontSize: "var(--font-16-px)",
            }}
            fieldStyle={{
              background: "transparent",
              border: "1px solid rgb(85 85 85)",
              color: "white",
            }}
          ></CustomText>
          <button
            onClick={handleSubmit}
            type="button"
            className="login-button"
            disabled={disableResetButton}
            style={{ cursor: disableResetButton ? "not-allowed" : "pointer" }}
          >
            {loading === true ? (
              <div className="button-spinner-container-new">
                <div className="button-spinner-new"></div>
              </div>
            ) : (
              <>
                <span>Reset Password</span>
              </>
            )}
          </button>
          <Link
            to="/"
            className="login-button"
            style={{ boxSizing: "border-box", textDecoration: "none" }}
          >
            Back to Login
          </Link>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
