export const setAgents = (agents) => {
  return {
    type: "SET_AGENTS",
    payload: agents,
  };
};

export const addToAgents = (agent) => ({
  type: "ADD_TO_AGENTS",
  payload: agent,
});

export const updateAgent = (agentId, agent) => ({
  type: "UPDATE_AGENT",
  payload: { id: agentId, agent: agent },
});
