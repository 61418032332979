import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import io from "socket.io-client";

const useSocketConnection = () => {
  const [socket, setSocket] = useState(null);
  const { selectedUserId } = useSelector((state) => state.conversation);

  useEffect(() => {
    // console.log("useSocketConnection: selectedUserId", selectedUserId);
    if (selectedUserId) {
      const socketSetup = io("https://chat-application.teatta.com", {
        query: {
          userId: selectedUserId,
        },
      });
      // console.log("useSocketConnection: socket is on");
      setSocket(socketSetup);

      return () => {
        // console.log("useSocketConnection: socket closing");
        socketSetup.close();
      };
    } else {
      if (socket !== null) {
        // console.log("useSocketConnection: socket closing due to null selectedUserId");
        socket.close();
        setSocket(null);
      }
    }
  }, [selectedUserId]);
  return {
    socket,
  };
};

export default useSocketConnection;
