import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import jwt_decode from "jwt-decode";
import { setCurrentUserId } from "../../../../redux/actions/conversationActions";

const useSetCurrentUserId = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      const decodedToken = jwt_decode(token);
      dispatch(setCurrentUserId(decodedToken._id));
    } else {
      dispatch(setCurrentUserId(null));
    }
    return () => {};
  }, []);
  return;
};

export default useSetCurrentUserId;
