import React, { useEffect, useRef } from "react";

const useScrollToView = () => {
  const scrollRef = useRef();
  useEffect(() => {
    setTimeout(() => {
      scrollRef.current?.scrollIntoView({ behavior: "smooth" });
    }, 100);
  });

  return {
    scrollRefComponent: <div ref={scrollRef}></div>,
  };
};

export default useScrollToView;
