import { createStore, applyMiddleware, combineReducers, compose } from "redux";
import { thunk } from "redux-thunk";
import toastReducer from "./reducers/toastReducer";
import loaderReducer from "./reducers/loaderReducer";
import conversationReducer from "./reducers/conversationReducer";
import manageAgentReducer from "./reducers/manageAgentReducer";
const rootReducer = combineReducers({
  toast: toastReducer,
  loader: loaderReducer,
  conversation: conversationReducer,
  agent: manageAgentReducer,
});
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
// Create the Redux store with the combined reducers and apply middleware
const store = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(thunk))
);

export default store;
