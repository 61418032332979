import React, { useState } from "react";
import "./ChatWindowAgent.css";
import { IoIosSend } from "react-icons/io";
import { useSelector } from "react-redux";
import useFetchChatDataWithId from "./hooks/useFetchChatDataWithId";
import { GoDotFill } from "react-icons/go";
import useScrollToView from "../../../hooks/useScrollToView";
import useSendMessage from "./hooks/useSendMessage";
import useSendFeedback from "./hooks/useSendFeedback";
import { MdOutlineAttachment } from "react-icons/md";
import { GrUploadOption } from "react-icons/gr";
import ImageUploadModal from "../ImageUploadModal/ImageUploadModal";
import { IoMdDoneAll } from "react-icons/io";
const ChatWindowAgent = ({ handleSkipChat, view }) => {
  const { selectedConversationId } = useSelector((state) => state.conversation);
  const { selectedConversationData } = useFetchChatDataWithId();
  const { scrollRefComponent } = useScrollToView();
  const [message, setMessage] = useState("");
  const [feedback, setFeedback] = useState("");
  const { handleSendMessage, sendingMessage } = useSendMessage();
  const { handleSendFeedback, modalBox } = useSendFeedback();
  const [showTransferScreen, setShowTransferScreen] = useState(false);
  const [transferReason, setTransferReason] = useState("");
  const [showError, setShowError] = useState(false);
  const [showImageUploadModal, setShowImageUploadModal] = useState(false);

  const handleTransferReason = () => {
    if (transferReason.trim() === "") {
      setShowError(true);
    } else {
      handleSkipChat(
        transferReason,
        setTransferReason,
        setShowTransferScreen,
        setShowError
      );
    }
  };

  const handleEnterDown = (e) => {
    if (e.key === "Enter") {
      handleSendMessage(message, setMessage);
    }
  };

  return (
    <>
      {selectedConversationId === "" ? (
        <div className="select-conv-to-start">Select Conversation to Start</div>
      ) : (
        <>
          <div className="chat-window-agent-container-header">
            <GoDotFill
              style={{
                color: selectedConversationData?.status ? "green" : "orange",
              }}
            />
            {selectedConversationData?.customerId?.email || "Loading..."}
            {view === "admin"
              ? null
              : selectedConversationData?.status === false && (
                  <button
                    onClick={() => {
                      setShowTransferScreen(true);
                      // setSkipChat(true);
                    }}
                  >
                    Transfer Chat
                  </button>
                )}
          </div>
          <div className="chat-window-agent-container-body">
            <div className="chat-window-agent-container-body-chat">
              {Array.isArray(selectedConversationData?.messages)
                ? selectedConversationData?.messages.map(
                    (message, messageIndex) => {
                      if (message.type === "message") {
                        return (
                          <div
                            className={`message-container ${
                              message?.senderId?._id ===
                              selectedConversationData?.customerId?._id
                                ? "left"
                                : "right"
                            }`}
                            key={messageIndex}
                          >
                            <div>
                              {/* Message Text */}
                              <div
                                className={`message ${
                                  message?.senderId?._id ===
                                  selectedConversationData?.customerId?._id
                                    ? "from"
                                    : "to"
                                }`}
                              >
                                {message?.message}
                              </div>
                              {/* Sender Agent Name */}
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  gap: "5px",
                                  color: "antiquewhite",
                                  fontSize: "12px",
                                  justifyContent: "flex-end",
                                  marginRight: "5px",
                                }}
                              >
                                {message?.senderId?.fullName || ""}
                                {message?.senderId?._id !==
                                selectedConversationData?.customerId?._id ? (
                                  message.loading ? (
                                    <GrUploadOption />
                                  ) : (
                                    <IoMdDoneAll />
                                  )
                                ) : null}
                              </div>
                            </div>
                          </div>
                        );
                      } else if (message.type === "internal") {
                        return (
                          <div className="message-container center">
                            <div className="internal-message">
                              {message.message}
                            </div>
                          </div>
                        );
                      } else if (message.type === "image") {
                        return (
                          <div
                            className={`message-container ${
                              message?.senderId?._id ===
                              selectedConversationData?.customerId?._id
                                ? "left"
                                : "right"
                            }`}
                            key={messageIndex}
                          >
                            <div>
                              {/* Message Text */}
                              <div
                                className={`message ${
                                  message?.senderId?._id ===
                                  selectedConversationData?.customerId?._id
                                    ? "from"
                                    : "to"
                                }`}
                                style={{ padding: "0px" }}
                              >
                                <img
                                  src={message.message}
                                  className="chat-agent-image-preview"
                                ></img>
                              </div>
                              {/* Sender Agent Name */}
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  gap: "5px",
                                  color: "antiquewhite",
                                  fontSize: "12px",
                                  justifyContent: "flex-end",
                                  marginRight: "5px",
                                }}
                              >
                                {message?.senderId?.fullName || ""}
                                {message?.senderId?._id !==
                                selectedConversationData?.customerId?._id ? (
                                  message.loading ? (
                                    <GrUploadOption />
                                  ) : (
                                    <IoMdDoneAll />
                                  )
                                ) : null}
                              </div>
                            </div>
                          </div>
                        );
                      }
                    }
                  )
                : null}
              {view === "admin"
                ? null
                : selectedConversationData?.agentFeedback === undefined &&
                  selectedConversationData?.status === true && (
                    <div className="message-container center">
                      <div className="message from feedback-container">
                        <textarea
                          placeholder="Type your feedback here..."
                          value={feedback}
                          onChange={(e) => {
                            setFeedback(e.target.value);
                          }}
                        ></textarea>
                        <div className="fb-submit-button-container">
                          <button
                            onClick={() => {
                              handleSendFeedback(feedback, setFeedback);
                            }}
                          >
                            Submit
                          </button>
                        </div>
                      </div>
                    </div>
                  )}

              {view === "admin"
                ? null
                : showTransferScreen && (
                    <div className="message-container center">
                      <div className="message from feedback-container">
                        <textarea
                          placeholder="Type your reason to transfer here..."
                          value={transferReason}
                          onChange={(e) => {
                            setTransferReason(e.target.value);
                          }}
                        ></textarea>
                        <div className="fb-submit-button-container">
                          <button onClick={handleTransferReason}>
                            Transfer
                          </button>
                        </div>
                      </div>
                    </div>
                  )}

              {selectedConversationData?.agentFeedback && (
                <div className="message-container center">
                  <div className="internal-message">
                    <b>Agent Feedback: </b>
                    {selectedConversationData.agentFeedback}
                  </div>
                </div>
              )}
              {selectedConversationData?.customerFeedback && (
                <div className="message-container center">
                  <div className="internal-message">
                    <b>Customer Feedback: </b>
                    {selectedConversationData.customerFeedback}
                  </div>
                </div>
              )}
            </div>
            {scrollRefComponent}
            {showImageUploadModal && (
              <ImageUploadModal
                showImageUploadModal={showImageUploadModal}
                setShowImageUploadModal={setShowImageUploadModal}
                handleSendMessage={handleSendMessage}
              />
            )}
            {view === "admin"
              ? null
              : selectedConversationData?.status === false && (
                  <div className="chat-window-agent-container-body-send-message">
                    <input
                      type="text"
                      placeholder="Type your message here..."
                      value={message}
                      onChange={(e) => setMessage(e.target.value)}
                      onKeyDown={handleEnterDown}
                      disabled={sendingMessage}
                    ></input>
                    <button
                      onClick={() => {
                        setShowImageUploadModal(true);
                      }}
                      disabled={sendingMessage}
                    >
                      <MdOutlineAttachment />
                    </button>
                    <button
                      onClick={() => {
                        handleSendMessage(message, setMessage, "message");
                      }}
                      disabled={sendingMessage}
                    >
                      <IoIosSend />
                    </button>
                  </div>
                )}
          </div>
        </>
      )}
    </>
  );
};

export default ChatWindowAgent;
