import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  markAsClosed,
  setSelectedConversationData,
} from "../redux/actions/conversationActions";

const useListenEndConversation = ({ socket }) => {
  const { selectedConversationId, selectedConversationData } = useSelector(
    (state) => state.conversation
  );
  const dispatch = useDispatch();
  useEffect(() => {
    if (socket) {
      const handleEndConversation = (conversationId) => {
        if (conversationId) {
          console.log(conversationId);
          const currentChatData = { ...selectedConversationData };
          currentChatData.messages = currentChatData.messages || [];
          currentChatData.messages.push({
            type: "internal",
            message: "Chat closed by customer",
          });
          dispatch(setSelectedConversationData(currentChatData));
          dispatch(markAsClosed(conversationId));
        }
      };
      socket.on("endConversation", handleEndConversation);
      return () => socket.off("endConversation", handleEndConversation);
    }
  }, [socket, selectedConversationId, selectedConversationData, dispatch]);
  return null;
};

export default useListenEndConversation;
