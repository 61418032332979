import React, { useEffect, useState } from "react";
import "./UpdateAgent.css";
import { IoCloseCircleOutline } from "react-icons/io5";
import CustomText from "../../../components/FormComponents/Text/CustomText";
import useHandleFormData from "../../../hooks/useHandleFormData";
import CustomSelect from "../../../components/FormComponents/Select/CustomSelect";
import {
  ConvertDateTime,
  ISO_to_HTML_Format,
} from "../../../functions/DateFunctions";
import axios from "axios";
import { useDispatch } from "react-redux";
import { updateAgent } from "../../../redux/actions/manageAgentActions";
const UpdateAgent = ({ closeFunction, selectedAgent }) => {
  const { formData, errors, handleChange, handleErrors, toast, setFormData } =
    useHandleFormData();
  const dispatch = useDispatch();
  useEffect(() => {
    if (selectedAgent?._id) {
      // console.log(selectedAgent);
      // console.log(selectedAgent?._id);
      setFormData({
        id: selectedAgent?._id,
        fullname: selectedAgent?.fullName,
        email: selectedAgent?.email,
        createdAt: selectedAgent?.createdAt,
        status: selectedAgent?.accountStatus,
      });
    }
  }, [selectedAgent]);
  const [loadingData, setLoadingData] = useState(false);
  const [loadingPwd, setLoadingPwd] = useState(false);
  const handleDataUpdate = () => {
    try {
      setLoadingData(true);
      axios
        .put(
          `/api/updateAgent/${formData.id}`,
          {
            fullName: formData.fullname,
            accountStatus: formData.status,
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        )
        .then((res) => {
          setLoadingData(false);
          // setRefreshAgentData(Date.now());
          dispatch(
            updateAgent(selectedAgent.id, {
              id: formData.id,
              _id: selectedAgent._id,
              fullName: formData.fullname,
              email: formData.email,
              userRole: "agent",
              createdAt: formData.createdAt,
              accountStatus: formData.status,
            })
          );
          toast(res.data?.message, "successToast");
          closeFunction();
        })
        .catch((err) => {
          setLoadingData(false);
          toast(err?.response?.data?.error || "Failed to Update", "errorToast");
          // console.log(e);
        });
    } catch (error) {
      toast("Oops! Something went wrong", "errorToast");
      setLoadingData(false);
      console.log(error);
    }
  };

  const handlePasswordUpdate = () => {
    try {
      setLoadingPwd(true);
      axios
        .put(
          `/api/updateAgentPassword/${selectedAgent.id}`,
          {
            newPassword: formData.password,
            newCPassword: formData.confirmPassword,
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        )
        .then((res) => {
          setLoadingPwd(false);
          //   setRefreshAgentData(Date.now());
          toast(res.data?.message, "successToast");
          closeFunction();
        })
        .catch((err) => {
          setLoadingPwd(false);
          toast(err?.response?.data?.error || "Failed to Update", "errorToast");
          // console.log(e);
        });
    } catch (error) {
      toast("Oops! Something went wrong", "errorToast");
      setLoadingPwd(false);
      console.log(error);
    }
  };

  return (
    <div className="add-agent-modal-container">
      <div className="update-agent-modal">
        {/* Header */}
        <div className="add-agent-header">
          <div className="add-agent-heading">Edit Agent</div>
          <button
            className="add-agent-close-button"
            onClick={() => {
              closeFunction();
            }}
          >
            <IoCloseCircleOutline />
          </button>
        </div>
        {/* Divider */}
        <div className="divider-add-agent"></div>
        {/* Body */}
        {selectedAgent?._id && (
          <div className="update-agent-body">
            <div className="update-agent-line ">
              <CustomText
                flex={"1"}
                label={"Full Name"}
                placeholder={"Full Name"}
                onChange={(e) => {
                  handleChange(e, "fullname");
                }}
                error={errors?.fullname}
                autocomplete="off"
                value={formData.fullname}
                fieldStyle={{
                  background: "transparent",
                  border: "1px solid rgb(85 85 85)",
                  color: "white",
                }}
                labelStyle={{
                  color: "rgb(189, 184, 184)",
                  fontSize: "var(--font-16-px)",
                }}
              ></CustomText>
              <CustomText
                flex={"1"}
                label={"Email"}
                placeholder={"Email"}
                onChange={(e) => {
                  handleChange(e, "email");
                }}
                error={errors?.email}
                autocomplete="off"
                value={formData.email}
                disabled={true}
                fieldStyle={{}}
                labelStyle={{
                  color: "rgb(189, 184, 184)",
                  fontSize: "var(--font-16-px)",
                }}
              ></CustomText>
            </div>
            <div className="update-agent-line ">
              <CustomText
                flex={"1"}
                label={"Joined Date"}
                placeholder={""}
                onChange={(e) => {}}
                autocomplete="off"
                value={ISO_to_HTML_Format(formData.createdAt)}
                disabled={true}
                fieldStyle={{}}
                labelStyle={{
                  color: "rgb(189, 184, 184)",
                  fontSize: "var(--font-16-px)",
                }}
              ></CustomText>
              <CustomSelect
                flex={"1"}
                label={"Status"}
                placeholder={"Status"}
                onChange={(e) => {
                  handleChange(e, "status");
                }}
                error={errors?.status}
                autocomplete="off"
                value={formData.status}
                fieldStyle={{
                  border: "1px solid rgb(85 85 85)",
                  color: "white",
                }}
                options={[
                  { value: "active", label: "Active" },
                  { value: "inactive", label: "Inactive" },
                ]}
                labelStyle={{
                  color: "rgb(189, 184, 184)",
                  fontSize: "var(--font-16-px)",
                }}
              ></CustomSelect>
            </div>
            <div
              className="update-agent-line"
              style={{ justifyContent: "flex-end" }}
            >
              <button
                className="add-new-agent-button mobile-space-add"
                onClick={handleDataUpdate}
                disabled={loadingData}
                style={{ cursor: loadingData ? "not-allowed" : "pointer" }}
              >
                {loadingData ? (
                  <div className="button-spinner-container-new">
                    <div className="button-spinner-new"></div>
                  </div>
                ) : (
                  <>
                    <span>Update</span>
                  </>
                )}
              </button>
            </div>
            <div className="update-agent-line ">
              <CustomText
                flex={"1"}
                label={"Password"}
                type={"password"}
                placeholder={"Password"}
                onChange={(e) => {
                  handleChange(e, "password");
                }}
                error={errors?.password}
                autocomplete="off"
                value={formData.password}
                fieldStyle={{
                  background: "transparent",
                  border: "1px solid rgb(85 85 85)",
                  color: "white",
                }}
                labelStyle={{
                  color: "rgb(189, 184, 184)",
                  fontSize: "var(--font-16-px)",
                }}
              ></CustomText>
              <CustomText
                type={"password"}
                flex={"1"}
                label={"Confirm Password"}
                placeholder={"Confirm Password"}
                onChange={(e) => {
                  handleChange(e, "confirmPassword");
                }}
                error={errors?.confirmPassword}
                autocomplete="off"
                value={formData.confirmPassword}
                fieldStyle={{
                  background: "transparent",
                  border: "1px solid rgb(85 85 85)",
                  color: "white",
                }}
                labelStyle={{
                  color: "rgb(189, 184, 184)",
                  fontSize: "var(--font-16-px)",
                }}
              ></CustomText>
            </div>
            <div
              className="update-agent-line"
              style={{ justifyContent: "flex-end" }}
            >
              <button
                className="add-new-agent-button"
                onClick={handlePasswordUpdate}
                disabled={loadingPwd}
                style={{ cursor: loadingPwd ? "not-allowed" : "pointer" }}
              >
                {loadingPwd ? (
                  <div className="button-spinner-container-new">
                    <div className="button-spinner-new"></div>
                  </div>
                ) : (
                  <>
                    <span>Save</span>
                  </>
                )}
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default UpdateAgent;
