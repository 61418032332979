import React, { useEffect } from "react";

const useNotification = () => {
  useEffect(() => {
    if (!("Notification" in window)) {
      console.log("No Notifications");
    } else {
      Notification.requestPermission();
    }
  }, []);

  const handleNotification = (title, message) => {
    const options = {
      body: message,
      //   icon: icon || null,
    };

    if (Notification.permission === "granted") {
      new Notification(title, options);
    } else {
      Notification.requestPermission().then((permission) => {
        if (permission === "granted") {
          new Notification(title, options);
        }
      });
    }
  };

  return { handleNotification };
};

export default useNotification;
