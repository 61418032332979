const headerData = [
  {
    name: "",
    minWidth: "10px",
    maxWidth: "10px",
    width: "10px",
    textAlign: "left",
    color: "#8894c1",
    fontSize: "var(--font-14-px)",
    tdStyle: {
      fontSize: "var(--font-14-px)",
    },
  },
  {
    name: "Name",
    minWidth: "50px",
    maxWidth: "auto",
    width: "200px",
    textAlign: "left",
    color: "#8894c1",
    fontSize: "var(--font-14-px)",
    tdStyle: {
      fontSize: "var(--font-14-px)",
    },
  },
  {
    name: "Email",
    minWidth: "20px",
    maxWidth: "auto",
    width: "50px",
    textAlign: "left",
    color: "#8894c1",
    fontSize: "var(--font-14-px)",
    tdStyle: {
      fontSize: "var(--font-14-px)",
    },
  },
  {
    name: "Status",
    minWidth: "50px",
    maxWidth: "auto",
    width: "5vw",
    textAlign: "center",
    color: "#8894c1",
    fontSize: "var(--font-14-px)",
    tdStyle: {
      fontSize: "var(--font-14-px)",
    },
  },
  {
    name: "Joined",
    minWidth: "50px",
    maxWidth: "auto",
    width: "50px",
    textAlign: "center",
    color: "#8894c1",
    fontSize: "var(--font-14-px)",
    tdStyle: {
      fontSize: "var(--font-14-px)",
    },
  },
];

const caption = "";

export { headerData, caption };
