import axios from "axios";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  addToAllMessages,
  resetAllMessages,
  setSelectedConversationData,
} from "../../../../redux/actions/conversationActions";

const useFetchChatDataWithId = () => {
  const { selectedConversationId, allMessages, selectedConversationData } =
    useSelector((state) => state.conversation);
  const dispatch = useDispatch();
  useEffect(() => {
    try {
      if (selectedConversationId !== "") {
        dispatch(resetAllMessages());
        axios
          .get(`/api/conversation/${selectedConversationId}`, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          })
          .then((res) => {
            let data = res.data;
            let formattedChat = res.data.messages.map((singleMessage) => {
              singleMessage.loading = false;
              return singleMessage;
            });
            data.messages = formattedChat;
            dispatch(setSelectedConversationData(data));
          })
          .catch((e) => {
            console.log(e);
          });
      }
    } catch (error) {
      console.log(error);
    }
  }, [selectedConversationId]);

  return {
    selectedConversationData: selectedConversationData,
    allMessages: allMessages,
  };
};

export default useFetchChatDataWithId;
