import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addNewConversationToSideBar } from "../redux/actions/conversationActions";

const useListenCompletedChat = ({ socket }) => {
  const { selectedConversationId, selectedConversationData } = useSelector(
    (state) => state.conversation
  );
  const dispatch = useDispatch();
  useEffect(() => {
    if (socket) {
      const socketAction = (convo) => {
        const payload = {
          _id: convo.conversationId,
          customerId: {
            _id: convo.customerId,
            email: convo.customerEmail,
          },
          agentId: "",
          status: true,
        };
        dispatch(addNewConversationToSideBar(payload));
      };
      socket.on("completedChat", socketAction);
      return () => socket.off("completedChat", socketAction);
    }
  }, [socket, selectedConversationId, selectedConversationData, dispatch]);
  return null;
};

export default useListenCompletedChat;
