import axios from "axios";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setSidebarConversations } from "../../../../redux/actions/conversationActions";

const useFetchSidebarConversations = () => {
  const { selectedConversationId, sidebarConversations, selectedUserData } =
    useSelector((state) => state.conversation);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    try {
      setLoading(true);
      console.log("User Data: ", selectedUserData);
      let api;
      if (selectedUserData.role === "admin") {
        api = "/api/conversation/completed";
      } else if (selectedUserData.role === "agent") {
        api = "/api/conversation";
      } else {
        api = false;
      }
      if (api) {
        axios
          .get(api, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          })
          .then((res) => {
            setLoading(false);
            dispatch(setSidebarConversations(res.data));
          })
          .catch((e) => {
            setLoading(false);
            console.error(e);
          });
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  }, [selectedUserData]);

  return {
    sidebarConversations: sidebarConversations,
    selectedConversationId: selectedConversationId,
    loading: loading,
  };
};

export default useFetchSidebarConversations;
