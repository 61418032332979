import jwt_decode from "jwt-decode";

export function TrimToNumOfCharacters(numberOfCharacters, string) {
  if (string.length > numberOfCharacters) {
    return string.substring(0, numberOfCharacters) + "...";
  } else {
    return string;
  }
}

export const getUserRole = () => {
  if (localStorage.getItem("token")) {
    const decoded = jwt_decode(localStorage.getItem("token"));
    if (decoded.role === "admin") {
      return "admin";
    } else {
      return "agent";
    }
  }
};
