import axios from "axios";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setSelectedConversationData } from "../../../../redux/actions/conversationActions";
import Modal from "../../../../components/Modal/Modal";
import { MdDone } from "react-icons/md";

const useSendFeedback = () => {
  const { selectedConversationId, selectedConversationData } = useSelector(
    (state) => state.conversation
  );
  const dispatch = useDispatch();
  const [openModal, setOpenModal] = useState(false);

  const handleSendFeedback = (feedback, setFeedback) => {
    try {
      axios
        .post(
          "/api/conversation/feedback",
          {
            conversationId: selectedConversationId,
            feedback: feedback,
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        )
        .then((res) => {
          // setOpenModal(true);
          const currentChatData = { ...selectedConversationData };
          currentChatData.agentFeedback = res.data?.agentFeedback;
          currentChatData.customerFeedback = res.data?.customerFeedback;
          dispatch(setSelectedConversationData(currentChatData));
          setFeedback("");
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (error) {
      console.error(error);
      alert("Error sending message");
    }
  };
  return {
    handleSendFeedback: handleSendFeedback,
    modalBox: (
      <Modal
        heading={"Feedback Submitted"}
        description={"Your feedback has been submitted "}
        buttonText={"Done"}
        svg={<MdDone id="cookieSvg" />}
        openModal={openModal}
        closeFunction={() => {
          setOpenModal(false);
        }}
      />
    ),
  };
};

export default useSendFeedback;
