import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addNewConversationToSideBar } from "../redux/actions/conversationActions";
import notificationSound from "../assets/general/notification.mp3";
import useNotification from "../hooks/useNotification";
const useListenNewServiceRequest = ({ socket }) => {
  const dispatch = useDispatch();
  const { selectedUserId, sidebarConversations } = useSelector(
    (state) => state.conversation
  );
  const { handleNotification } = useNotification();
  useEffect(() => {
    if (selectedUserId) {
      if (socket) {
        const handleNewServiceRequest = (serviceRequest) => {
          // const sound = new Audio(notificationSound);
          // try {
          //   sound.play();
          // } catch (error) {
          //   alert("New Message Request");
          // }
          handleNotification("Alert", "New Message Request");
          dispatch(addNewConversationToSideBar(serviceRequest));
        };
        socket.on("serviceRequest", handleNewServiceRequest);
        return () => {
          socket.off("serviceRequest", handleNewServiceRequest);
        };
      }
    }
  }, [socket, dispatch, selectedUserId, sidebarConversations]);
  return null;
};

export default useListenNewServiceRequest;
