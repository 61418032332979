import axios from "axios";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  removeConversationFromSideBar,
  setSelectedConversationData,
  setSelectedConversationId,
} from "../../../../redux/actions/conversationActions";

const useManuallySkipChat = () => {
  const { selectedConversationId, selectedConversationData } = useSelector(
    (state) => state.conversation
  );
  const dispatch = useDispatch();
  const [successModal, setShowSuccessModal] = useState(false);
  const handleSkipChat = (
    transferReason,
    setTransferReason,
    setShowTransferScreen,
    setShowError
  ) => {
    try {
      axios
        .post(
          "/api/conversation/skip",
          {
            conversationId: selectedConversationId,
            reason: transferReason,
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        )
        .then((res) => {
          // alert("Conversation Transfered");
          setShowSuccessModal(true);
          setShowTransferScreen(false);
          setShowError(false);
          setTransferReason("");
          dispatch(setSelectedConversationData({}));
          dispatch(setSelectedConversationId(""));
          dispatch(removeConversationFromSideBar(selectedConversationId));
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (error) {
      console.error(error);
      alert("Error sending message");
    }
  };
  return {
    handleSkipChat: handleSkipChat,
    setShowSuccessModal: setShowSuccessModal,
    successModal: successModal,
  };
};

export default useManuallySkipChat;
