import { Outlet, useNavigate } from "react-router-dom";
import SidebarAgent from "../../containers/ChatAgent/SidebarAgent/SidebarAgent";
import { useState } from "react";
import Modal from "../../components/Modal/Modal";
import { useDispatch } from "react-redux";
import { clearStore } from "../../redux/actions/conversationActions";
import { FaPowerOff } from "react-icons/fa";
export default function AdminLayout() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showLogoutScreen, setShowLogoutScreen] = useState(false);
  return (
    <>
      <SidebarAgent setShowLogoutScreen={setShowLogoutScreen} view={"admin"} />
      <main>
        <div className="chat-agent-main-container">
          <div className="chat-window-agent-container">
            <Outlet />
          </div>
        </div>
      </main>
      <Modal
        openModal={showLogoutScreen}
        heading={"Logout"}
        description={"Are you sure you want to logout?"}
        buttonText={"Logout"}
        showDeclineButton={true}
        declineFunction={() => {
          setShowLogoutScreen(false);
        }}
        closeFunction={() => {
          setShowLogoutScreen(false);
          localStorage.removeItem("token");
          localStorage.removeItem("role");
          dispatch(clearStore());
          navigate("/");
        }}
        svg={<FaPowerOff id="cookieSvg" />}
      />
    </>
  );
}
