import React, { useState } from "react";
import "./AddAgent.css";
import useHandleFormData from "../../../hooks/useHandleFormData";
import CustomText from "../../../components/FormComponents/Text/CustomText";
import { IoCloseCircleOutline } from "react-icons/io5";
import axios from "axios";
import { useDispatch } from "react-redux";
import { addToAgents } from "../../../redux/actions/manageAgentActions";
const AddAgent = ({ closeFunction, setRefreshList }) => {
  const { formData, errors, handleChange, handleErrors, toast, setFormData } =
    useHandleFormData();
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const handleSubmit = () => {
    try {
      setLoading(true);
      axios
        .post(
          `/api/createAgent`,
          {
            fullName: formData.fullname,
            email: formData.email,
            password: formData.password,
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        )
        .then((res) => {
          setLoading(false);
          setRefreshList(Date.now());
          // dispatch(
          //   addToAgents({
          //     fullName: formData.fullname,
          //     email: formData.email,
          //     userRole: "agent",
          //     createdAt: Date.now(),
          //     accountStatus: "active",
          //   })
          // );
          toast(res.data?.message, "successToast");
          closeFunction();
        })
        .catch((err) => {
          setLoading(false);
          toast(err?.response?.data?.error || "Failed to Update", "errorToast");
          // console.log(e);
        });
    } catch (error) {
      toast("Oops! Something went wrong", "errorToast");
      setLoading(false);
      console.log(error);
    }
  };
  return (
    <div className="add-agent-modal-container">
      <div className="add-agent-modal">
        <div className="add-agent-header">
          <div className="add-agent-heading">Add Agent</div>
          <button
            className="add-agent-close-button"
            onClick={() => {
              closeFunction();
            }}
          >
            <IoCloseCircleOutline />
          </button>
        </div>
        <div className="divider-add-agent"></div>
        <div className="add-agent-body">
          <CustomText
            flex={"100%"}
            label={"Full Name"}
            placeholder={"Full Name"}
            onChange={(e) => {
              handleChange(e, "fullname");
            }}
            error={errors?.fullname}
            autocomplete="off"
            value={formData.fullname}
            fieldStyle={{
              background: "transparent",
              border: "1px solid rgb(85 85 85)",
              color: "white",
            }}
            labelStyle={{
              color: "rgb(189, 184, 184)",
              fontSize: "var(--font-16-px)",
            }}
          ></CustomText>
          <CustomText
            flex={"100%"}
            label={"Email"}
            autocomplete="off"
            placeholder={"Email"}
            onChange={(e) => {
              handleChange(e, "email");
            }}
            error={errors?.email}
            value={formData.email}
            fieldStyle={{
              background: "transparent",
              border: "1px solid rgb(85 85 85)",
              color: "white",
            }}
            labelStyle={{
              color: "rgb(189, 184, 184)",
              fontSize: "var(--font-16-px)",
            }}
          ></CustomText>
          <CustomText
            flex={"100%"}
            autocomplete="off"
            label={"Password"}
            placeholder={"Password"}
            type={"password"}
            onChange={(e) => {
              handleChange(e, "password");
            }}
            error={errors?.password}
            value={formData.password}
            labelStyle={{
              color: "rgb(189, 184, 184)",
              fontSize: "var(--font-16-px)",
            }}
            fieldStyle={{
              background: "transparent",
              border: "1px solid rgb(85 85 85)",
              color: "white",
            }}
          ></CustomText>
        </div>
        <div className="add-agent-footer">
          <button
            onClick={handleSubmit}
            type="button"
            className="login-button"
            // disabled={loading}
          >
            {loading ? (
              <div className="button-spinner-container-new">
                <div className="button-spinner-new"></div>
              </div>
            ) : (
              <>
                <span>Create Agent</span>
              </>
            )}
          </button>
        </div>
      </div>
    </div>
  );
};

export default AddAgent;
