import React from "react";
import "./Modal.css";
const Modal = ({
  heading,
  description,
  buttonText,
  svg,
  closeFunction,
  openModal,
  showDeclineButton,
  declineFunction,
}) => {
  if (openModal) {
    return (
      <div className="card-container">
        <div class="card">
          {svg || null}
          <p class="cookieHeading">{heading || "We use cookies"}</p>
          <p class="cookieDescription">
            {description ||
              "We use cookies to ensure that we give you the best experience on ourwebsite."}
          </p>
          <div class="buttonContainer">
            {showDeclineButton && (
              <button
                class="declineButton"
                onClick={declineFunction ? declineFunction : () => {}}
              >
                Cancel
              </button>
            )}
            <button
              class="acceptButton"
              onClick={closeFunction ? closeFunction : () => {}}
            >
              {buttonText || "Done"}
            </button>
            {/* <button class="declineButton">Decline</button> */}
          </div>
        </div>
      </div>
    );
  }
};

export default Modal;
