export const setSidebarConversations = (data) => {
  return {
    type: "SET_SIDEBAR_CONVERSATIONS",
    payload: data,
  };
};

export const addToAllMessages = (data) => {
  console.log(data);
  if (Array.isArray(data)) {
    return {
      type: "ADD_TO_ALL_MESSAGES_BULK",
      payload: data,
    };
  } else {
    return {
      type: "ADD_TO_ALL_MESSAGES",
      payload: data,
    };
  }
};

export const resetAllMessages = () => {
  return {
    type: "RESET_ALL_MESSAGES",
  };
};

export const clearStore = () => {
  return {
    type: "CLEAR_STORE",
  };
};

export const setSelectedConversationId = (data) => {
  return {
    type: "SET_SELECTED_CONVERSATION_ID",
    payload: data,
  };
};

export const setCurrentUserId = (data) => {
  return {
    type: "SET_CURRENT_USER_ID",
    payload: data,
  };
};

export const setCurrentUserData = (data) => {
  return {
    type: "SET_CURRENT_USER_DATA",
    payload: data,
  };
};

export const markAsClosed = (conversationId) => {
  return {
    type: "MARK_AS_CLOSED",
    payload: conversationId,
  };
};

export const setSelectedConversationData = (data) => {
  return {
    type: "SET_SELECTED_CONVERSATION_DATA",
    payload: data,
  };
};

export const addNewConversationToSideBar = (data) => {
  return {
    type: "ADD_NEW_CONVERSATION_TO_SIDEBAR",
    payload: data,
  };
};

export const removeConversationFromSideBar = (conversationId) => {
  return {
    type: "REMOVE_CONVERSATION_FROM_SIDEBAR",
    payload: conversationId,
  };
};
