import axios from "axios";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setSelectedConversationData } from "../../../../redux/actions/conversationActions";
import { showToastMessage } from "../../../../redux/actions/toastActions";

const useSendMessage = () => {
  const {
    selectedConversationId,
    selectedConversationData,
    selectedUserId,
    selectedUserData,
  } = useSelector((state) => state.conversation);
  const dispatch = useDispatch();

  const [sendingMessage, setSendingMessage] = useState(false);

  const handleSendMessage = (message, setMessage, type) => {
    try {
      setSendingMessage(true);
      if (type !== "image") {
        if (message.trim() === "") {
          return;
        }
      }
      const currentChatData = { ...selectedConversationData };
      currentChatData.messages = currentChatData.messages || [];
      currentChatData.messages.push({
        type: type || "message",
        loading: true,
        message: type === "image" ? message?.previewUrl : message,
        senderId: {
          _id: selectedUserId,
          fullName: selectedUserData?.fullName || "Me",
        },
      });
      dispatch(setSelectedConversationData(currentChatData));
      let formData = new FormData();
      if (type === "image") {
        formData.append("image", message?.imageFile);
        formData.append("message", "");
        formData.append("type", "image");
      } else {
        formData.append("message", message);
        formData.append("type", "message");
      }
      formData.append("conversationId", selectedConversationId);
      axios
        .post("/api/message/agent", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        })
        .then((res) => {
          const currentChatData = { ...selectedConversationData };
          currentChatData.messages = currentChatData.messages || [];
          currentChatData.messages.pop();
          currentChatData.messages.push({
            type: type || "message",
            loading: false,
            message: type === "image" ? message?.previewUrl : message,
            senderId: {
              _id: selectedUserId,
              fullName: selectedUserData?.fullName || "Me",
            },
          });
          dispatch(setSelectedConversationData(currentChatData));
          setMessage("");
          setSendingMessage(false);
        })
        .catch((e) => {
          dispatch(showToastMessage("Error Sending Message !", "errorToast"));
          const currentChatData = { ...selectedConversationData };
          currentChatData.messages = currentChatData.messages || [];
          currentChatData.messages.pop();
          dispatch(setSelectedConversationData(currentChatData));
          setSendingMessage(false);
        });
    } catch (error) {
      dispatch(showToastMessage("Error Sending Message !", "errorToast"));
      const currentChatData = { ...selectedConversationData };
      currentChatData.messages = currentChatData.messages || [];
      currentChatData.messages.pop();
      dispatch(setSelectedConversationData(currentChatData));
      setSendingMessage(false);
    }
  };
  return {
    handleSendMessage: handleSendMessage,
    sendingMessage,
  };
};

export default useSendMessage;
