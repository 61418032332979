import React, { useEffect, useState } from "react";
import "./SidebarAgent.css";
import { FaPowerOff } from "react-icons/fa";
import useFetchSidebarConversations from "./hooks/useFetchSidebarConversations";
import { useDispatch } from "react-redux";
import {
  setCurrentUserData,
  setSelectedConversationData,
  setSelectedConversationId,
} from "../../../redux/actions/conversationActions";
import jwt_decode from "jwt-decode";
import { BsFillPeopleFill } from "react-icons/bs";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { getUserRole } from "../../../functions/TextFunctions";

const SidebarAgent = ({ setShowLogoutScreen, view }) => {
  const { sidebarConversations, selectedConversationId, loading } =
    useFetchSidebarConversations();
  const dispatch = useDispatch();
  const location = useLocation();
  // useListenNewServiceRequest();
  const navigate = useNavigate();

  const [agentName, setAgentName] = useState("");
  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      const decodedToken = jwt_decode(token);
      dispatch(setCurrentUserData(decodedToken));
      setAgentName(decodedToken.fullName);
    } else {
      setAgentName("Agent");
    }
    return () => {};
  }, []);
  return (
    <div
      className={
        "sidebar-agent-container " +
        (getUserRole() === "admin" && location.pathname !== "/admin"
          ? "change-height-on-admin"
          : "")
      }
    >
      <div className="user-section">
        <div className="user-info">
          {/* <img src={userImage} className="user-image-sidebar-agent" /> */}
          Hi, {agentName}
        </div>
        <div className="logout">
          <FaPowerOff
            onClick={() => {
              setShowLogoutScreen(true);
            }}
          />
        </div>
      </div>
      {view === "admin" && (
        <div className="user-section">
          <Link
            className="agent-management-button"
            to={"/admin/management/agent"}
          >
            <BsFillPeopleFill /> Agent Management
          </Link>
        </div>
      )}
      <div
        className={
          "convo-section " +
          (getUserRole() === "admin" && location.pathname !== "/admin"
            ? "hide-on-mobile"
            : "")
        }
      >
        <div className={"agent-main-sidebar-container "}>
          {loading ? (
            <div className="button-spinner-container-new">
              <div className="button-spinner-new"></div>
            </div>
          ) : Array.isArray(sidebarConversations) ? (
            sidebarConversations.map((singleConversation, index) => {
              return (
                <div
                  className={
                    (singleConversation.status
                      ? "single-convo convo-done"
                      : "single-convo convo-pending") +
                    (selectedConversationId === singleConversation._id
                      ? " active-conv"
                      : "")
                  }
                  onClick={() => {
                    dispatch(setSelectedConversationData({}));
                    dispatch(setSelectedConversationId(singleConversation._id));
                    navigate(getUserRole() === "admin" ? "/admin" : "/agent");
                  }}
                >
                  {singleConversation.customerId.email}
                </div>
              );
            })
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default SidebarAgent;
