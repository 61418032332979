import React, { useState } from "react";
import "./ChatAgent.css";
import ChatWindowAgent from "./ChatWindow/ChatWindowAgent";
import useSetCurrentUserId from "./ChatWindow/hooks/useSetCurrentUserId";
import useSocketConnection from "../../hooks/useSocketConnection";
import useListenNewServiceRequest from "../../SocketListeners/useListenNewServiceRequest";
import useListenNewMessage from "../../SocketListeners/useListenNewMessage";
import useListenEndConversation from "../../SocketListeners/useListenEndConversation";
import useListenAutoEndConversationZeroMessages from "../../SocketListeners/useListenAutoEndConversationZeroMessages";
import useListenAutoEndConversationNoReplyCustomer from "../../SocketListeners/useListenAutoEndConversationNoReplyCustomer";
import useListenAutoTransferChat from "../../SocketListeners/useListenAutoTransferChat";
import Modal from "../../components/Modal/Modal";
import { useDispatch } from "react-redux";
import useManuallySkipChat from "./ChatWindow/hooks/useManuallySkipChat";

const ChatAgent = () => {
  const { socket } = useSocketConnection();
  useSetCurrentUserId();
  useListenNewServiceRequest({ socket: socket });
  useListenNewMessage({ socket: socket });
  useListenEndConversation({ socket: socket });
  useListenAutoEndConversationZeroMessages({ socket: socket });
  useListenAutoEndConversationNoReplyCustomer({ socket: socket });
  const { modalBox } = useListenAutoTransferChat({ socket: socket });
  const { handleSkipChat, successModal, setShowSuccessModal } =
    useManuallySkipChat();

  return (
    <>
      <ChatWindowAgent handleSkipChat={handleSkipChat} />
      {modalBox}
      <Modal
        openModal={successModal}
        heading={"Transfer Chat"}
        description={"Chat Transfered"}
        buttonText={"Done"}
        closeFunction={() => {
          setShowSuccessModal(false);
        }}
      />
    </>
  );
};

export default ChatAgent;
